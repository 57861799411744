/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {FormattedNumber, IntlProvider, useIntl} from 'react-intl'

import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {QueryRequestProvider as RatingQueryRequestProvider} from '@/app/modules/rating-by-therapist/core/QueryRequestProvider'
import {QueryResponseProvider as RatingQueryResponseProvider} from '@/app/modules/rating-by-therapist/core/QueryResponseProvider'
import {
  QueryRequestProvider,
  useQueryRequest,
} from '@/app/modules/report-trend-analysis/core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
} from '@/app/modules/report-trend-analysis/core/QueryResponseProvider'
import {PageTitle} from '../../../../_metronic/layout/core'
import {TopArea} from './components/TopArea'
import {TopCity} from './components/TopCity'
import {TopEmployees} from './components/TopEmployee'
import {TopOutlet} from './components/TopOutlet'
import {TopQueryRequestProvider} from './core/QueryRequestTopProvider'
import {AreaQueryResponseProvider, useAreaQueryResponseData} from './core/QueryResponseAreaProvider'
import {CityQueryResponseProvider, useCityQueryResponseData} from './core/QueryResponseCityProvider'
import {
  OutletQueryResponseProvider,
  useOutletQueryResponseData,
} from './core/QueryResponseOutletProvider'
import { useAuth } from '@/app/modules/auth'

const DashboardPage: FC = () => {
  const {updateState} = useQueryRequest()
  const {currentUser} = useAuth()
  const data = useQueryResponseData()
  const response = useQueryResponse()
  const isLoading = useQueryResponseLoading()
  const dataCity = useCityQueryResponseData()
  const dataArea = useAreaQueryResponseData()
  const currentDate = new Date()
  const arrayMonth = [
    (currentDate.getMonth() - 4).toString(),
    (currentDate.getMonth() - 3).toString(),
    (currentDate.getMonth() - 2).toString(),
    (currentDate.getMonth() - 1).toString(),
    currentDate.getMonth().toString(),
    (currentDate.getMonth() + 1).toString(),
  ]

  useEffect(() => {
    updateState({
      month: [],
      // month: arrayMonth,
    })
    
  }, [])

  return (
    <>
      {
        ["72b0ad30-d847-47bc-af21-80f0eddca117"].includes(currentUser?.detail_data?.job?.job_id as string) ? (
          <div className='g-5 w-100 h-100 text-center fs-1 text-primary fw-bold'>
            Welcome Back, {currentUser?.detail_data?.fullname}
          </div>
        ) : (
          <>
            {/* begin::Row */}
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
              {/* begin::Col */}
              <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3'>
                <BarChart
                  className='area-wib shadow-wit'
                  color='primary'
                  title='Revenue'
                  data={data?.trend_transaction}
                  headers={['Bulan', 'Total Transaksi', 'Total Sales']}
                  formatCurrency={['total_sales']}
                  series={[
                    {
                      name: 'Revenue',
                      data: data?.trend_transaction?.map((item) => item.total_sales),
                    },
                  ]}
                  categories={data?.trend_transaction?.map((item) =>
                    item.month?.split('2023')[0].substring(0, 3).trim()
                  )}
                  total={data?.summary_trend?.sales}
                  isLoading={isLoading || (response.response?.response as any) === 'no result data'}
                />
              </div>
              {/* end::Col */}

              {/* begin::Col */}
              <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3'>
                <BarChart
                  className='area-wib shadow-wit'
                  color='success'
                  title='Treatments'
                  data={data?.trend_product}
                  headers={['Bulan', 'Total Quantity']}
                  series={[
                    {
                      name: 'Quantity',
                      data: data?.trend_product?.map((item) => item.total_qty),
                    },
                  ]}
                  categories={data?.trend_product?.map((item) =>
                    item.month?.split('2023')[0].substring(0, 3).trim()
                  )}
                  total={data?.summary_trend?.product}
                  isCurrency={false}
                  isLoading={isLoading || (response.response?.response as any) === 'no result data'}
                />
              </div>
              {/* end::Col */}

              {/* begin::Col */}
              <div className='col-xl-12'>
                <TopEmployees className='flex-fill col-xl-12' />
              </div>
              <div className='d-flex flex-row gap-4'>
                <TopOutlet className='flex-fill col-xl-4' />
                <TopCity className='flex-fill col-xl-4' />
                <TopArea className='flex-fill col-xl-4' />
              </div>
              {/* end::Col */}

              {/* begin::Col */}
              {/* <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3 mt-0'>
                <AccountPayable />
              </div> */}
              {/* end::Col */}

              {/* begin::Col */}
              {/* <div className='col-md-6 col-lg-6 col-xl-6 mb-md-5 mb-xl-3 mt-0'>
                <AccountMovable />
              </div> */}
              {/* end::Col */}

              {/* <div className='col-4 mb-md-5 mb-xl-3'> */}
              {/* begin::Col */}
              {/* <InventoryAsset
                  className='card-xl mb-xl-8 shadow-wit-2'
                  // className='card-xl-stretch mb-xl-8'
                  svgIcon='chart-simple'
                  color='white'
                  iconColor='primary'
                  title='Rp 500.000.000'
                  description='Inventory Asset'
                />
                <InventoryCategory className='mb-5 mb-xl-10 shadow-wit-2' /> */}
              {/*   end::Col */}
              {/* </div> */}
              {/* <div className='col-8 mb-md-5 mb-xl-3'>
                <InventoryRestock className='card-xxl-stretch mb-3 mb-xl-8 shadow-wit-2' />
              </div> */}
            </div>
            {/* end::Row */}
          </>
        )
      }
    </>
  )
}
const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <RatingQueryRequestProvider>
        <QueryRequestProvider>
          <TopQueryRequestProvider>
            <RatingQueryResponseProvider>
              <QueryResponseProvider>
                <OutletQueryResponseProvider>
                  <CityQueryResponseProvider>
                    <AreaQueryResponseProvider>
                      <PageTitle breadcrumbs={[]}>
                        {intl.formatMessage({id: 'MENU.HOME'})}
                      </PageTitle>
                      <DashboardPage />
                    </AreaQueryResponseProvider>
                  </CityQueryResponseProvider>
                </OutletQueryResponseProvider>
              </QueryResponseProvider>
            </RatingQueryResponseProvider>
          </TopQueryRequestProvider>
        </QueryRequestProvider>
      </RatingQueryRequestProvider>
    </>
  )
}

export {DashboardWrapper}
