import ApexCharts, {ApexOptions} from 'apexcharts'
import React, {useEffect, useMemo, useRef} from 'react'
import {FormattedNumber, IntlProvider} from 'react-intl'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {TrendTransaction} from '@/app/modules/report-trend-analysis/core/_models'
import clsx from 'clsx'
import LoadingChart from '@/app/modules/apps/dashboard/components/LoadingChart'

type Props = {
  className: string
  color: string
  secondColor?: string
  title?: string
  subtitle?: string
  series?: any
  categories?: any
  height?: string
  width?: string
  total?: number
  isCurrency?: boolean
  isTotal?: boolean
  data?: any[]
  headers?: any[]
  formatCurrency?: any[]
  layout?: 'vertical' | 'horizontal'
  isLoading?: boolean
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
}

const BarChart: React.FC<Props> = ({
  className,
  color = 'primary',
  title = '',
  subtitle = '',
  height = '300px',
  series = [],
  categories = [],
  total = 0,
  isCurrency = true,
  isTotal = true,
  data = [],
  headers = [],
  formatCurrency = [],
  layout = 'vertical',
  isLoading = false,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, series])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, color, series, categories, isCurrency)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  const columnLayout = useMemo(
    () => clsx({col: layout === 'horizontal', 'col-12': layout === 'vertical'}),
    [layout]
  )

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          {subtitle && <span className='text-muted fw-semibold fs-7'>{subtitle}</span>}
        </h3>
        {isTotal && (
          <div className='card-toolbar'>
            <span className={`card-title fw-bold fs-1 text-${color}`}>
              {isCurrency ? 'Rp. ' : ''} <FormattedNumber value={total} />
            </span>
          </div>
        )}
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className={columnLayout}>
            {isLoading && <LoadingChart />}
            {data === null && <label className='form-label'>no result data</label>}
            {!isLoading && <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: height}} />}
          </div>
          {data && headers && (
            <div className={columnLayout}>
              <div className='table-responsive mt-5 px-5'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-30px'>No</th>
                      {headers.map((x) => (
                        <th className='min-w-30px' key={x}>
                          {x}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody className='text-dark mb-1 fs-6'>
                    {data && data?.length > 0 ? (
                      data?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-semibold text-hover-primary d-block fs-6'
                            >
                              {index + 1}
                            </a>
                          </td>
                          {Object.keys(item).map((key) => {
                            let useFormatter = false
                            if (formatCurrency.includes(key)) {
                              useFormatter = true
                            }
                            return (
                              <td key={item[key]}>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <span className='text-dark fw-bold fs-6'>
                                      {!useFormatter ? (
                                        item[key]
                                      ) : (
                                        <>
                                          Rp.{' '}
                                          <IntlProvider locale='id'>
                                            <FormattedNumber
                                              value={item[key] || 0}
                                              maximumFractionDigits={2}
                                            />
                                          </IntlProvider>{' '}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            )
                          })}
                          {/* <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-dark me-2 fs-7 fw-semibold'>
                            {item.total_transaction || 0}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-dark me-2 fs-7 fw-semibold'>
                            Rp.{' '}
                            <IntlProvider locale='id'>
                              <FormattedNumber value={item.total_sales || 0} />
                            </IntlProvider>{' '}
                          </span>
                        </div>
                      </div>
                    </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={headers.length + 1}>
                          <div className='w-100 text-center'>Belum ada data</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {BarChart}

function getChartOptions(
  height: number,
  color: string,
  series: any,
  categories,
  isCurrency: boolean
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue(`--bs-${color}`)
  const secondaryColor = getCSSVariableValue(`--bs-gray-300`)

  return {
    series: series,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      // width: series?.length * 1000,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (val) {
          return isCurrency ? 'Rp. ' + numberWithCommas(val) : '' + numberWithCommas(val)
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return isCurrency ? 'Rp. ' + numberWithCommas(val) : '' + numberWithCommas(val)
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
