import {BarChart} from '@/_metronic/partials/content/chart/BarChart'
import {useEffect, useState} from 'react'
import {useQueryResponse, useQueryResponseData, useQueryResponseLoading} from './core/QueryResponseProvider'

const Overview = () => {

  const isLoading = useQueryResponseLoading()
  const response = useQueryResponse()
  const data = useQueryResponseData()
  //Revenue Sharing
  const [arrayOfSeriesWalkIn, setArrayOfSeriesWalkIn] =
    useState<number[]>([])
  const [arrayOfSeriesGuestHotel, setArrayOfSeriesGuestHotel] =
    useState<number[]>([])
  const [arrayOfSeriesCabin, setArrayOfSeriesCabin] =
    useState<number[]>([])
  const [arrayOfSeriesInRoom, setArrayOfSeriesInRoom] =
    useState<number[]>([])
  const [arrayOfSeriesGuestHotelCabin, setArrayOfSeriesGuestHotelCabin] =
    useState<number[]>([])
  const [arrayOfSeriesGuestHotelInRoom, setArrayOfSeriesGuestHotelInRoom] =
    useState<number[]>([])
  const [arrayOfLabelsGuestType, setArrayOfLabelsGuestType] = useState<string[]>([])

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
  }

  useEffect(() => {
      setArrayOfSeriesWalkIn([])
      setArrayOfSeriesGuestHotel([])
      setArrayOfSeriesCabin([])
      setArrayOfSeriesInRoom([])
      setArrayOfSeriesGuestHotelCabin([])
      setArrayOfSeriesGuestHotelInRoom([])
      setArrayOfLabelsGuestType([])

    if (data) {
      //Report Booking Type
      if (data) {
        const arrayOfLabelsGuestTypeString = data?.map((obj) =>
          obj.periode ? obj.periode : ''
        )
        setArrayOfLabelsGuestType(arrayOfLabelsGuestTypeString)
        const arrayOfSeriesWalkInString = data?.map((obj) =>
          obj.total_walk_in! ? obj.total_walk_in! : 0
        )
        setArrayOfSeriesWalkIn(arrayOfSeriesWalkInString)
        const arrayOfSeriesCabinString = data?.map((obj) =>
          obj.total_cabin ? obj.total_cabin : 0
        )
        setArrayOfSeriesCabin(arrayOfSeriesCabinString)
        const arrayOfSeriesInRoomString = data?.map((obj) =>
          obj.total_in_room ? obj.total_in_room : 0
        )
        setArrayOfSeriesInRoom(
          arrayOfSeriesInRoomString
        )

        const arrayOfSeriesGuestHotelString = data?.map(
          (obj, index) => {
            return obj.total_guest_hotel ? obj.total_guest_hotel : 0
          }
        )
        setArrayOfSeriesGuestHotel(
          arrayOfSeriesGuestHotelString
        )

        const arrayOfSeriesGuestHotelCabinString = data?.map((obj) =>
          obj.total_guest_hotel_cabin ? obj.total_guest_hotel_cabin : 0
        )
        setArrayOfSeriesGuestHotelCabin(arrayOfSeriesGuestHotelCabinString)
        const arrayOfSeriesGuestHotelInRoomString = data?.map((obj) =>
          obj.total_guest_hotel_in_room ? obj.total_guest_hotel_in_room : 0
        )
        setArrayOfSeriesGuestHotelInRoom(
          arrayOfSeriesGuestHotelInRoomString
        )

      }
    }
  }, [data])

  return (
    <>
      <div className='col-xl-12 g-5 g-xl-8 pt-10'>
        <BarChart
          className='area-wib shadow-wit'
          color='success'
          title='Guest Type'
          subtitle=''
          height='400px'
          series={[
            {
              name: 'Total Walk In',
              data: arrayOfSeriesWalkIn,
            },
            {
              name: 'Total Guest Hotel',
              data: arrayOfSeriesGuestHotel,
            },
          ]}
          categories={arrayOfLabelsGuestType}
          total={0}
          isTotal={false}
          data={data?.map((x) => ({
            month: x.periode,
            total_walk_in: x.total_walk_in,
            total_guest_hotel: x.total_guest_hotel,
          }))}
          isCurrency={false}
          headers={['Periode', 'Total Walk In', 'Total Guest Hotel']}
          isLoading={isLoading || (response.response?.response as any) === 'no result data'}
        />
        <BarChart
          className='mt-10 area-wib shadow-wit'
          color='primary'
          title='Facility Type'
          subtitle=''
          height='400px'
          series={[
            {
              name: 'Total Cabin',
              data: arrayOfSeriesCabin,
            },
            {
              name: 'Total In-Room',
              data: arrayOfSeriesInRoom,
            },
          ]}
          categories={arrayOfLabelsGuestType}
          total={0}
          isTotal={false}
          data={data?.map((x) => ({
            month: x.periode,
            total_cabin: x.total_cabin,
            total_in_room: x.total_in_room,
          }))}
          headers={['Periode', 'Total Cabin', 'Total In-Room']}
          isCurrency={false}
          isLoading={isLoading || (response.response?.response as any) === 'no result data'}
        />
        <BarChart
          className='mt-10 area-wib shadow-wit'
          color='info'
          title='Guest Hotel - Facility Type'
          subtitle=''
          height='400px'
          series={[
            {
              name: 'Total Guest Hotel Cabin',
              data: arrayOfSeriesGuestHotelCabin,
            },
            {
              name: 'Total Guest Hotel In-Room',
              data: arrayOfSeriesGuestHotelInRoom,
            },
          ]}
          categories={arrayOfLabelsGuestType}
          total={0}
          isTotal={false}
          data={data?.map((x) => ({
            month: x.periode,
            total_cabin: x.total_guest_hotel_cabin,
            total_in_room: x.total_guest_hotel_in_room,
          }))}
          headers={['Periode', 'Total Guest Hotel Cabin', 'Total Guest Hotel In-Room']}
          isCurrency={false}
          isLoading={isLoading || (response.response?.response as any) === 'no result data'}
        />
      </div>
    </>
  )
}

export default Overview
